.mainContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@media (max-width: 820px) {
    .mainContainer {
        justify-content: space-between;
    }
}