.topHeader div ul {
    padding-right: 10px;
}

.topHeader span {
    margin-left: 10px;
}

.pageHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 40px);
    z-index: 100;
    padding: 20px;
    position: fixed;
}

.topHeader {
    bottom: 0;
    align-items: flex-end;
    z-index: 1;
}

@media (max-width: 820px) {

    .pageHeader {
        position: unset;
    }

    .topHeader {
        justify-content: space-between;
        align-items: center;
    }
}