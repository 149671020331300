@font-face {
    font-family: "VCR OSD Mono-Regular";
    src: url('../public/Fonts/VCR_OSD_MONO.ttf');
  }

:root {
    --black: rgba(0, 0, 0, 1);
    --blue: #357EC7;
    --grey: #414243;
    --darkblue: #1E272E;
    --crimson: #721B01;
    --white: #ffffff;
    --green: #61811D;
    --blueblack: #1D2021;

    --blue-text-shadow: rgba(53, 126, 199, 0.25);

    --font-size-xs: 21px;
    --font-size-s: 28px;
    --font-size-m: 30px;
    --font-size-l: 32px;
    --font-size-xl: 36px;

    --font-family: "VCR OSD Mono-Regular", monospace;

    --border-radius: 10px;

    --form-padding: 10px;

    /* Secondary Variables */

    --accent-color: var(--blue);

    /* Gradients */
    --gradient-1: linear-gradient(90deg, rgba(97,129,29,1) 10%, rgba(114,27,1,1) 82%);
    --gradient-2: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    --gradient-3: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    --gradient-4: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    --gradient-5: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
}


body {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), var(--blueblack) url('../public/Images/img_startpage.jpg') center/cover no-repeat;
    background-size: cover;
}

* {
    font-family: var(--font-family);
    color: var(--blue);
    text-transform: uppercase;
}

h1 {
    font-size: var(--font-size-xl);
}
h2 {
    font-size: var(--font-size-l);
}
h3 {
    font-size: var(--font-size-m);
}
h4 {
    font-size: var(--font-size-s);
}
h5 {
    font-size: var(--font-size-xs);
}

.vcr-text {
    text-shadow: 0px 4px 4px var(--blue-text-shadow);
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
}

.flex-row.center {
    align-items: center;
}

.space-evenly {
    justify-content: space-around !important;
}

.text-center {
    text-align: center;
}

.font-size-xl {
    font-size: var(--font-size-xl)
}

.font-size-l {
    font-size: var(--font-size-l)
}

.font-size-m {
    font-size: var(--font-size-m)
}

.font-size-s {
    font-size: var(--font-size-s)
}

.font-size-xs {
    font-size: var(--font-size-xs)
}

.white-text {
    color: white !important;
}

ul {
    list-style-type: none;
}

li {
    margin: 15px 0;
}

.is-mobile {
    display: none;
}

.is-desktop {
    display: block;
}

@media only screen and (max-width: 768px) {

    body {
        text-align: center;
        height: 100%;
    }

    .is-desktop {
        display: none;
    }

    .is-mobile {
        display: block;
    }
}