/* Component Styles */

button {
    background: none;
    border: 2px var(--white) solid;
    border-radius: 10px;
    padding: 2px 10px;
    height: 50px;
    color: var(--white);
    cursor: pointer;
}

.control-button {
    width: 200px;
}

button:hover {
    filter: brightness(75%)
}

.button-accept { background-color: var(--accent-color); } .button-reject { background-color: var(--crimson); }
.button-default { background-color: var(--darkblue);}

/* Page Styles */

.page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.page-row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {

    h2.vcr-text {
        font-size: 28px;
    }

    .page-row {
        flex-direction: column
    }

    .buttons a button {
        margin: 0.5em 0;
        outline: none;
    }

    .flex-column-mobile {
        flex-direction: column;
        width: 100%;
    }
    .page-content {
        height: 100%;
    }
}


  

  .custom-cursor {
    cursor: none;
    width: 50px;
    height: 50px;
    pointer-events: none;
    position: fixed;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-image: url('https://res.cloudinary.com/ds4krgzbj/image/upload/q_auto,f_auto/v1696624482/PAR/cursorPAR_yluzt8.png');
    background-size: cover;
  }

  @media only screen and (max-width: 768px) {

    .custom-cursor {
        visibility: hidden;
    }
}

