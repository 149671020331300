.audio-btn {
    right: 5px;
    font-size: 14px;
}

.bottomHeader span {
    padding-top: 40px;
    padding-left: 10px;
}

.bottomHeader button {
    margin-bottom: 30px;
    z-index: 999;
}

.bottomHeader div .blur-button a {
    text-decoration: none !important;
}